h3 {
    margin-left: 15px;
  }
  .fileInput {
    border-bottom: 4px solid #d3d3d3;
    border-right: 4px solid #d3d3d3;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    padding: 10px;
    margin: 15px;
    cursor: pointer;
  }
  .imgPreview {
      position: relative;
    text-align: center;
    height: 120px;
    width: 120px;
  }
  .imgPreview img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .previewText {
    width: 100%;
    margin-top: 20px;
  }
  .submitButton {
    padding: 12px;
    margin-left: 10px;
    background: #fff;
    border: 4px solid #d3d3d3;
    border-radius: 15px;
    font-weight: 700;
    font-size: 10pt;
    cursor: pointer;
  }
  .submitButton:hover {
    background: #efefef;
  }
  .centerText {
    text-align: center;
    width: 200px;
  }
  
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer !important;
  }


  .upload-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #FFF;
    border: 1px solid #ccc;
    cursor: pointer !important;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

