.Courses-page {
    min-height: 700px;
    
    .card-img-top {
        height: 220px;
    }
}

.DescCouses {
    min-height: 700px;
    .course-image {
        height: 280px;
    }
}
.course-status {
    position : absolute;
    top: 70px;
    right: 20px;
}