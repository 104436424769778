.Exams {
  .item-exam {
    transition: all 0.5s ease-in-out;
    &:hover {
      transition: all 0.5s ease-in-out;
      background: #fff;
      filter: drop-shadow(11px 11px 46px rgba(22, 22, 22, 0.07));
    }
  }
  .status-exams {
    font-size: 9px;
  }
}

.start-exam {
  display: flex;
  justify-content: center;
}

.ExamDetails {
  .question-content {
    font-size: 18px;
    line-height: 33px;
    font-weight: normal;
  }
  .progress {
    height: 25px;
    border-radius: 30px;
    .progress-bar {
      height: 25px;
      border-radius: 30px;
    }
  }
}

// raido button
.z-radio {
  font-size: 11px !important;
  width: 100%;
  display: block;
  margin: 20px;
  margin-right: 0;
  margin-bottom: 0;
  input[type="radio"] {
    font-size: 11px !important;
    appearance: none;
    width: 100%;
    &:checked + label {
      &:before {
        background: #5578eb;
        z-index: 99;
      }
      &:after {
        transform: scale(1);
      }
    }
  }
  label {
    font-size: 11px !important;
    background: #ece9f1;
    border-radius: 7px;
    width: 100%;
    height: 55px;
    position: relative;
    color: #000;
    cursor: pointer;
    //   padding-right: 30px;
    font-weight: normal;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transform: scale(0);
      background: #5578eb;
      border-radius: 7px;
      transition: all 0.3s ease-in-out;
    }
  }
  h6 {
    position: relative;
    z-index: 999;
    font-size: 14px;
    font-weight: normal !important;
  }
}
.form-group {
  h4 {
    margin-top: 29px;
    font-weight: normal;
  }
  .label {
    margin-top: 35px !important;
    font-weight: normal;
  }
}
.submit-exam {
  z-index: 101;
  float: right;
  justify-content: center;
  align-items: center;
}
.submit-btn-wrapper {
  float: right;
  margin-top: 40px;
  position: absolute;
  right: 0;

  .submit-btn {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
  }
}
