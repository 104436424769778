.Ticket {
  min-height: 100vh;
}
.TicketDetail {
  min-height: 100vh;
}
.TicketSummary {
  transition: all 0.5s ease-in-out;
  &:hover {
    border: 1px solid #ccc;
    transition: all 0.5s ease-in-out;
  }
}
.user-logo {
  width: 50px;
  height: 50px;
  display: inline;
  margin: auto;
}
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-date {
  align-self: flex-end;
  justify-self: flex-end;
  float: right;
}
.ticket-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 425px) {
  .card-title {
    font-size: small;
  }
}
@media (max-width: 396px) {
  .card-container {
    display: flex;
    flex-direction: column;
  }
  .card-title {
    margin-top: 20px;
  }
}
