.timer-enter {
    font-size: xx-large;
    width: 15vw;
    height: 40px;
    z-index: 101;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb822;
    opacity: .2;
    color: white;
}

.timer-enter-active {
    opacity: .8;
    font-size: xx-large;
    width: 15vw;
    height: 40px;
    z-index: 101;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb822;
    color: white;
    transition: opacity 1000ms,
}
.timer-enter-done {
    opacity: .8;
    font-size: xx-large;
    width: 15vw;
    height: 40px;
    z-index: 101;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb822;
    color: white;
}
.timer {
    opacity: .8;
    font-size: xx-large;
    width: 15vw;
    height: 40px;
    justify-content: center;
    display: flex;
    background-color: #ffb822;
    color: white;
}