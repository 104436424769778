.table-view {
    width: 100%;
    thead, tbody, tr, td, th { display: block; }
    tr:after {
        content: ' ';
        display: block;
        visibility: hidden;
        clear: both;
    }
    thead th {
        text-align: center;
        align-content: center;
        justify-content: center;
    }
    
    tbody {
        max-height: 200px;
        overflow-y: auto;
    }
    
    
    tbody td, thead th {
        width: 33.333%;
        float: left;
    }
    tbody tr td {
        text-align: center;
        justify-content: center;
    }
}